<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div v-if="carregado">
      <tela :caixas_conta="caixas" />
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { http } from "../../../../helpers/easyindustriaapi/config";
//import Multiselect from "vue-multiselect";
import tela from "./tela.vue";
export default {
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    tela,
  },
  data() {
    return {
      tittle: "Transferencia entre Contas",
      titleBody: "Transferencia entre Contas",
      select_de: null,
      select_para: null,
      carregado: false,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Financeiro",
          href: "/",
          active: true,
        },
        {
          text: "Pagamento a fornecedor",
          href: "/financeiro/pagamentofornecedor",
          active: true,
        },
      ],
      caixas: [],
      caixas_conta: [],
    };
  },
  mounted() {
    this.get_caixas();
    this.caixa_empresa();
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  methods: {
    // corrigido erro
    
    caixa_empresa() {
      this.caixas.forEach((caixa) => {
        this.caixas_conta.push(caixa.empresa);
      });
      //console.log(this.caixas_conta);
    },
    get_embarques() {},
    async get_caixas() {
      try {
        let response = await http.get(
          "/movimento-caixa?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.caixas = response.data;
          //this.caixas_conta.push(this.caixas.empresa)
          console.log(this.caixas);
          setTimeout(() => {
            this.carregado = true;
          }, 10);
        } else {
          this.caixas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
  },
};
</script>
<style>
</style>