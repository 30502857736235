<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-3">
            <Label>Definir Padrão :</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-3">
            <Label>De :</Label>
            <Multiselect
              @select="select_origem($event)"
              v-model="select_de"
              :options="caixas_conta2"
              label="apelido"
            ></Multiselect>
          </div>
          <div class="col-md-3">
            <Label>Para :</Label>
            <Multiselect
              @select="select_destino($event)"
              v-model="select_para"
              :options="caixas_conta2"
              label="apelido"
            ></Multiselect>
          </div>
          <div class="col-md-3">
            <Label>{{ "*Data :" }}</Label>
            <input
              v-model="transf.data"
              type="date"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4">
            <Label>{{ "Valor:" }}</Label>
            <input
              @input="formatInput_valor()"
              v-model="transf.valor"
              type="text"
              class="form-control text-left"
              placeholder="digite o valor"
            />
          </div>
          <div class="col-md-4">
            <Label>{{ "*Descrição de Saída na Conta de Origem:" }}</Label>
            <input
              v-model="transf.desc_entrada"
              type="text"
              class="form-control text-left"
              placeholder="digite"
            />
          </div>
          <div class="col-md-4">
            <Label>{{ "*Descrição de Entrada na Conta de Origem:" }}</Label>
            <input
              v-model="transf.desc_saida"
              type="text"
              class="form-control text-left"
              placeholder="digite"
            />
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <b-form-checkbox
              v-model="transf.vinculo"
              value="3"
              @change="verifica_vinculo()"
            >
              Vincular a um aviso de Embarque
            </b-form-checkbox>
          </div>
        </div>
        <div
          v-if="validar_vic"
          class="row mt-4 d-flex justify-content-center align-items-center"
        >
          <div class="col-md-6 text-center">
            <multiselect
              @select="selecionar_estado($event)"
              v-model="select_estado"
              :options="estados"
              label="estado"
            >
            </multiselect>
          </div>
          <div class="col-md-6 text-center">
            <multiselect
              @select="selecionar_vinculo($event)"
              v-model="select_vinculado"
              :options="vet_vinculos"
              label="nome"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click.prevent="doPost()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import Layout from "../../../layouts/main";

import { http } from "../../../../helpers/easyindustriaapi/config";
import Multiselect from "vue-multiselect";

export default {
  props: {
    caixas_conta: { type: Array },
  },
  components: {
    //Layout,
    Multiselect,
  },
  data() {
    return {
      caixas: [],
      select_de: null,
      select_para: null,
      caixas_conta2: [],
      valor: 0,
      data: null,
      vet_vinculos: [
        {
          id: 1,
          nome: "vinculado",
        },
        {
          id: 2,
          nome: "Não vinculado",
        },
      ],
      select_estado: null,
      select_vinculado: null,
      desc_saida: null,
      desc_entrada: null,
      vinculo: null,
      validar_vic: false,
      transf: {
        valor: "0,00",
        select_de: null,
        select_para: null,
        data: null,
        desc_saida: null,
        desc_entrada: null,
        vinculo: null,
        estado: null,
        sit_vinculado: null,
        movimento_caixa_id:1
      },
      estados: [
        {
          id: 1,
          estado: "brasilia",
        },
        {
          id: 2,
          estado: "pernambuco",
        },
      ],
    };
  },
  created() {
    this.caixas = this.caixas_conta;
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    // console.log(this.caixas)
    this.caixa_empresa();
  },
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    select_origem(objeto) {
      this.transf.select_de = objeto.id;
    },
    select_destino(objeto) {
      this.transf.select_para = objeto.id;
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.transf.valor == "string") {
        this.transf2 = this.transf;
        let stringValor = this.transf2.valor;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.transf.valor = valorFlutuante;
      }
    },

    post_transferencia() {},
    async doPost() {
      if (this.transf.valor != null) {
        this.coverter_float();
      }
      // this.transf.empresa_id = this.currentEmpresa.id;
      // this.transf.user_id = this.currentUser.id;
      try {
        let response = await http.post(
          "/saldo-conta?empresa_id=" + this.currentEmpresa.id,
          this.transf
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            console.log(response.data);
            //this.getData();
            //this.abri_menu_modal()
            // this.inserindo = false;
             this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    selecionar_estado(event) {
      //console.log(event);
      this.transf.estado = event.id;
    },
    selecionar_vinculo(event) {
      this.transf.sit_vinculado = event.id;
      //console.log(event);
    },
    caixa_empresa() {
      this.caixas.forEach((caixa) => {
        this.caixas_conta2.push(caixa.empresa);
      });
      //console.log(this.caixas_conta);
    },
    verifica_vinculo() {
      if (this.transf.vinculo == 3) {
        this.validar_vic = true;
      } else {
        this.validar_vic = false;
      }
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.transf.valor.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.transf.valor = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
  },
};
</script>

<style>
</style>